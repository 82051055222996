
// @ is an alias to /src test env
import { reactive, ref } from "vue";
import * as moment from "moment";
import Swal from "sweetalert2";
import Auth from "../../services/routes";
import Header from "../../components/Header/index.vue";
import lodash from "lodash";
import Multiselect from "@vueform/multiselect";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
export default {
  name: "Home",
  components: { Header, Multiselect, QuillEditor },
  data() {
    return {
      MusculosSelecionados: [],
      value: [],
      link: "",
      categoria: "",
      categorias: "",
      Musculo: [],
      Musculos: "",
      Equipamento: [],
      Equipamentos: "",
      render_img: false,
      render_infografico: false,
      render: false,
      fileImage: null,
      showModal: false,
      fileName: false,
      activetab: 1,
      referencia: "",
      descricao: "",
      nome: null,
      imagem_musculo: null,
      load: false,
      tableData: [],
      API: null,
      expandRowKeys: [],
      pages: 1,
      tipo: 1,
      name_video: false,
      name_image: false,
      name_infografico: false,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100, 500, 1000],
        total: 0,
      },
    };
  },
  computed: {
    queriedData() {
      let result = lodash.orderBy(this.tableData, ["ID"], ["desc"]);
      /*
                                      let filtered = lodash.filter(result, item => {
                                        if(this.filter.filterDefault === "ALL") {
                                          return item.DES_FINANCE_CATEGORY.indexOf('') >=0;
                                        }
                                        else {
                                          return item.DES_FINANCE_CATEGORY.indexOf(this.filter.filterDefault) >=0;
                                        }  
                                      });

                                      this.tableDataFiltered = filtered
                                      */
      return result.slice(this.from, this.to);
    },
    pagedData() {
      if (!this.tableDataFiltered)
        return this.tableData.slice(this.from, this.to);
      else return this.tableDataFiltered.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      if (!this.tableDataFiltered)
        this.pagination.total = this.tableData.length;
      else this.pagination.total = this.tableDataFiltered.length;

      if (!this.tableDataFiltered) return this.tableData.length;
      else return this.tableDataFiltered.length;
    },
  },

  methods: {
    addMusculo() {
      var obj = {};
      let i = 0;
      for (; i <= this.Musculos.length - 1; i++) {
        if (this.Musculos[i].nome === this.value) {
          obj.id = this.Musculos[i].id;
          obj.nome = this.Musculos[i].nome;
          obj.tipo = 1;
        }
      }
      this.MusculosSelecionados.push(obj);
    },

    handleExpandChange(row, expandedRows) {
      const id = row.idParaOrganizar;
      const lastId = this.expandRowKeys[0];
      // disable mutiple row expanded
      this.expandRowKeys = id === lastId ? [] : [id];
    },
    clicker() {},
    rowClicked(row) {
      this.$refs.tableData.toggleRowExpansion(row);
    },
    showFile() {
      this.load = true;
      var file = this.$refs.loadImage.files[0];
      this.name_video = this.$refs.loadImage.files[0].name;
      const getBase64 = (file) =>
        new Promise(function (resolve, reject) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject("Error: ", error);
        });
      getBase64(file)
        .then((result) => {
          this.render = result;
          this.load = false;
        })
        .catch((e) => console.log("deu erro:", e));
    },
    showFile2() {
      this.load = true;
      var file = this.$refs.loadImage2.files[0];
      this.name_image = this.$refs.loadImage2.files[0].name;
      const getBase64 = (file) =>
        new Promise(function (resolve, reject) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject("Error: ", error);
        });
      getBase64(file)
        .then((result) => {
          this.render_img = result;
          this.load = false;
        })
        .catch((e) => console.log("deu erro:", e));
    },
    showFile3() {
      this.load = true;
      var file = this.$refs.loadImage3.files[0];
      this.name_infografico = this.$refs.loadImage3.files[0].name;
      const getBase64 = (file) =>
        new Promise(function (resolve, reject) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject("Error: ", error);
        });
      getBase64(file)
        .then((result) => {
          this.render_infografico = result;
          this.load = false;
        })
        .catch((e) => console.log("deu erro:", e));
    },
    SendImage() {
      this.load = true;
      let data = {
        nome: this.nome,
        arquivo: this.render,
        imagem: this.render_img,
        infografico: this.render_infografico,
        referencia: this.referencia,
        descricao: this.descricao,
        link: this.link,
        categoria: this.categoria,
        musculos: this.MusculosSelecionados,
        equipamentos: this.Equipamento,
      };
      console.log(data);
      Auth.CadastroExercicio(data)
        .then((r) => {
          console.log(r.data.resultado);
          if (r.data.resultado === "Exercício cadastrado anteriormente") {
            this.$notify({
              message: "Exercício já cadastrado!",
              title: "Falha!",
              type: "Error",
            });
          } else {
            this.$notify({
              message: "Cadastrado com Sucesso!",
              title: "Sucesso",
              type: "success",
            });
          }
        })
        .catch((e) => {
          this.$notify({
            message: "Não foi possivel fazer esta ação",
            title: "Erro",
            type: "danger",
          });
        })
        .finally(() => {
          this.showModal = false;
          this.load = false;
          this.render = false;
          this.nome = "";
          this.render_img = null;
          this.render_infografico = null;
          this.render = null;
          this.referencia = "";
          this.descricao = "";
          this.link = "";
          this.categoria = [];
          this.MusculosSelecionados = [];
          this.Equipamento = [];
          this.name_video = false;
          this.name_image = false;
          this.name_infografico = false;
          this.getItens();
          this.pages = 1;
        });
    },
    getItens() {
      this.load = true;
      Auth.getExercicios()
        .then((r) => {
          this.tableData = r.data.reverse();
          let i = 0;
          for (; i <= this.tableData.length - 1; i++) {
            this.tableData[i].idParaOrganizar = i;
          }
        })
        .finally(() => {
          this.load = false;
        });
    },
    getCategorias() {
      this.load = true;
      Auth.getCategoria()
        .then((r) => {
          this.categorias = r.data.categorias.reverse();
        })
        .finally(() => {
          this.load = false;
        });
    },
    getMusculos() {
      this.load = true;
      Auth.getMusculo()
        .then((r) => {
          this.Musculos = r.data.musculos.reverse();
          let i = 0;
          for (; i <= this.Musculos.length - 1; i++) {
            this.Musculos[i].value = this.Musculos[i].nome;
          }
        })
        .finally(() => {
          this.load = false;
        });
    },
    getEquipamentos() {
      this.load = true;
      Auth.getEquipamento()
        .then((r) => {
          this.Equipamentos = r.data.equipamento.reverse();
          let i = 0;
          for (; i <= this.Equipamentos.length - 1; i++) {
            this.Equipamentos[i].value = this.Equipamentos[i].nome_equipamento;
          }
        })
        .finally(() => {
          this.load = false;
        });
    },
    exclude(index) {
      let products = this.MusculosSelecionados;
      products.splice(index, 1);
    },
    verifica() {
      let data = {
        id: this.referencia,
      };
      Auth.verificaReferencia(data)
        .then((r) => {
          if (r.data.resultado === 1) {
            this.referencia = '';
            this.$notify({
              message: "Referência já utilizada!",
              title: "Erro",
              type: "danger",
            });
          }
        })
        .finally(() => {
          this.load = false;
        });
    },
    deleteItem(row) {
      this.load = true;
      let data = {
        id: row.id,
        tabela: 4,
      };
      Auth.deleteItem(data)
        .then((r) => {
          this.$notify({
            message: "Deletado com Sucesso!",
            title: "Sucesso",
            type: "success",
          });
        })
        .finally(() => {
          this.load = false;
          this.getItens();
        });
    },
  },
  mounted() {},
  created() {
    this.getItens();
    this.getCategorias();
    this.getMusculos();
    this.getEquipamentos();
    Auth.defaultW().then((r) => {
      this.API = r;
    });
  },
  setup() {
    const state = reactive({
      testes: "dCasa",
      numero: ref(0),
    });

    return {
      state,
    };
  },
};
